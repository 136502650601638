import './App.css';
import React from 'react'
import Home from './Screen/Home';
import { Route, Routes, BrowserRouter, HashRouter} from "react-router-dom";
import Whoweare from './Screen/Whoweare';
import Journey from './Screen/Journey';
import Differentiators from './Screen/Differentiators';
import Terms from './Screen/Terms';
import Privacy from './Screen/Privacy';
import Donate from './Screen/Donate';
import Seemore from './Screen/Seemore';
import Newseemore from './Screen/Newseemore';
import Seemorelist from './Screen/Seemorelist';
import ScrollToTop from './Components/ScrollToTop';
import DistrictEvent from './Screen/DistrictEvent';
import Districtseemore from './Screen/Districtseemore';
import Seemoredata from './Screen/Seemoredata';

function App() {
  return (
    <BrowserRouter>
    <ScrollToTop />
    <Routes>
    <Route exact path="/" element={<Home />} />
    <Route path="/Whoweare" element={<Whoweare />} />
    <Route path="/Journey" element={<Journey />} />
    <Route path="/Differentiators" element={<Differentiators />} />
    <Route path="/Terms" element={<Terms />} />
    <Route path="/Privacy" element={<Privacy />} />
    <Route path="/Donate" element={<Donate />} />
    <Route path="/Seemore/:id" element={<Seemore />} />
    <Route path="/Seemorelist/:id" element={<Seemorelist />} />
    <Route path="/Newseemore/:id" element={<Newseemore />} />
    <Route path="/DistrictEvent" element={<DistrictEvent />} />
    <Route path="/Districtseemore/:id" element={<Districtseemore />} />
    <Route path="/Seemoredata/:id" element={<Seemoredata />} />
    </Routes>
    </BrowserRouter>
  );
}

export default App;