import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import "../Assets/Additionalcss/Differentiate.css";
import Arrow from "../Components/Arrow";
import Axios from "axios";
import { BASE_URL } from "../Config/Base";
import { Image_URL } from "../Config/Base";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import sameera from "../Assets/Images/Differentiator/sameera.jpeg";

function Differentiators() {
  const [Load, setLoad] = useState(false);
  const [data, setData] = useState([]);
  useEffect(() => {
    Axios({
      method: "GET",
      url: `${BASE_URL}/Foundation/getDifferentiators`,
    }).then((resp) => {
      setData(resp.data);
      setLoad(true);
    });
  }, []);
  return (
    <div>
      <Header />
      <Arrow />

      {/* Displaying Differentiator */}
      <div className="col-lg-12 col-sm-12 col-md-12">
        <div className="dif-container">
          <div className="differentiator-card">
            {Load == false ? (
              <div className="seemore-skeleton">
                <Stack spacing={15}>
                  <div className="skeleton">
                    <div className="skeleton-div">
                      <Skeleton
                        variant="rectangular"
                        height={400}
                        width={300}
                      />
                    </div>
                  </div>
                </Stack>
              </div>
            ) : (
              data.map((item, q) => {
                return (
                  <div className="diff-card1" key={q}>
                    <div className="content-overlay"></div>
                    <div className="diff-cardimage">
                      {" "}
                      <img src={sameera} />{" "}
                    </div>
                    <div className="middle">
                      <p className="diffcard-text">{item.title}</p>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default Differentiators;
