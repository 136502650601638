import React, { Component, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import '../Assets/Additionalcss/Home.css'
import part1 from '../Assets/Images/Beapart/Beapart1.jpg'
import part2 from '../Assets/Images/Beapart/Beapart2.jpg'
import part3 from '../Assets/Images/Beapart/Beapart3.jpg'
import location from '../Assets/Images/location.png'
import peo from '../Assets/Images/people.png'
import school from '../Assets/Images/school.png'
import state from '../Assets/Images/state.png'
import volunteer from '../Assets/Images/volunteer.png'
import Button from '../Components/Button'
import Slider from "react-slick";
import Axios from 'axios';
import Arrow from '../Components/Arrow';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/autoplay"
import { EffectCoverflow, Pagination, Autoplay, Navigation, EffectFade } from "swiper";
import D1 from '../Assets/Images/Difference/Difference_1.jpg'
import D2 from '../Assets/Images/Difference/Difference_2.jpg'
import D3 from '../Assets/Images/Difference/Difference_3.jpg'
import D4 from '../Assets/Images/Difference/Difference_4.jpg'
import D5 from '../Assets/Images/Difference/Difference_5.jpg'
import D6 from '../Assets/Images/Difference/Difference_6.jpg'
import D7 from '../Assets/Images/Difference/Difference_7.jpg'
import D8 from '../Assets/Images/Difference/Difference_8.jpg'
import D9 from '../Assets/Images/Difference/Difference_9.jpg'
import D10 from '../Assets/Images/Difference/Difference_10.jpg'
import AD from '../Assets/Images/Contributors/AD.jpg'
import BG from '../Assets/Images/Contributors/BG.jpg'
import MNB from '../Assets/Images/Contributors/MNB.jpeg'
import MS from '../Assets/Images/Contributors/MS.jpg'
import MP from '../Assets/Images/Contributors/MP.jpg'
import TS from '../Assets/Images/Contributors/TS.jpg'
import VB from '../Assets/Images/Contributors/VB.jpg'
import JK from '../Assets/Images/Contributors/JK.jpg'
import VC from '../Assets/Images/Contributors/Vc.jpg'
import SH from '../Assets/Images/Contributors/SH.jpg'
import SM from '../Assets/Images/Contributors/SM.jpeg'
import VK from '../Assets/Images/Contributors/VK.jpeg'
import AM from '../Assets/Images/Contributors/AM.jpg'
import Bb from '../Assets/Images/Contributors/Babanna.jpg'
import LK from '../Assets/Images/Contributors/Lokesh.jpg'
import SK from '../Assets/Images/Contributors/Shivakumar.jpg'
import ST from '../Assets/Images/Contributors/Santhosh.jpeg'
import Th from '../Assets/Images/Contributors/Thara.jpeg'
import Ma from '../Assets/Images/Contributors/Manu.jpg'
import MR from '../Assets/Images/Contributors/Maruthi.jpg'
import Ra from '../Assets/Images/Contributors/Ranganna.jpeg'
import Sak from '../Assets/Images/Contributors/Sashikumar.jpeg'
import Thi from '../Assets/Images/Contributors/Thippesh.jpeg'
import SP from '../Assets/Images/Contributors/SP.jpg'
import PD from '../Assets/Images/Contributors/PD.jpg'
import PR from '../Assets/Images/Contributors/PR.jpg'
import MG from '../Assets/Images/Contributors/MG.jpg'
import MH from '../Assets/Images/Contributors/MH.jpg'
import { Image_URL } from '../Config/Base'
import "swiper/css/navigation";
import ImgCarousel from '../Components/ImgCarousel';
import Count from '../Components/Count';
import {BASE_URL} from '../Config/Base'
import CompressImageFromURL from '../Components/CompressImageFromURL';

function Home() {
    const [data, setData] = useState([])
    const [time, setTime] = useState([])
    const [load, setLoad] = useState(false)
    const settings = {
        infinite: true, speed: 500, slidesToShow: 3, slidesToScroll: 1, autoplay: true,
        responsive: [
            {
                breakpoint: 768, settings: { infinite: true, speed: 500, slidesToShow: 1, slidesToScroll: 1, autoplay: true, }
            },
        ]
    };

    useEffect(() => {
        Axios({
            method: "GET",
            url: `${BASE_URL}/Foundation/getContributors`
        }).then((resp) => {
            setData(resp.data)
        })
        setTimeout(() => {
            setLoad(true)
        }, 2000);
    }, [])

    return (
        <div>
            

            <div className='dummy-header'>

            </div>
            <Header />
            <Arrow />
            
           
            {/* Starting Carousel */}
            <div className="Starting-carousel">
                <ImgCarousel />
            </div>

          
            {/* Difference We have made */}
            <div className="Difference-carousel-container">
                <h3 className="futuretext">Difference we have made</h3>
                <div className="Difference-carousel">
                    <Slider {...settings}>
                        <div className="img-card">
                            <div className="new-card">
                                <img className="responsive2" src={D1} />
                                {/* <CompressImageFromURL imageUrl={D1} imageClassName="responsive2" /> */}
                                <p className="card-school">GHPS - Chikkaballapur</p>
                            </div>
                        </div>
                        <div className="img-card">
                            <div className="new-card">
                                <img className="responsive2" src={D2} />
                                {/* <CompressImageFromURL imageUrl={D2} imageClassName="responsive2" /> */}
                                <p className="card-school">GHPS - Ganjalagunte</p>
                            </div>
                        </div>
                        <div className="img-card">
                            <div className="new-card">
                                <img className="responsive2" src={D3} />
                                {/* <CompressImageFromURL imageUrl={D3} imageClassName="responsive2" /> */}
                                <p className="card-school">GHPS - Gudibande</p>
                            </div>
                        </div>

                        <div className="img-card">
                            <div className="new-card">
                                <img className="responsive2" src={D4} />
                                {/* <CompressImageFromURL imageUrl={D4} imageClassName="responsive2" /> */}
                                <p className="card-school">GHPS - Dodda Madhure Kunigal</p>
                            </div>
                        </div>

                        <div className="img-card">
                            <div className="new-card">
                                <img className="responsive2" src={D5} />
                                {/* <CompressImageFromURL imageUrl={D5} imageClassName="responsive2" /> */}
                                <p className="card-school">GLPS - Davanagere</p>
                            </div>
                        </div>

                        <div className="img-card">
                            <div className="new-card">
                                <img className="responsive2" src={D6} />
                                {/* <CompressImageFromURL imageUrl={D6} imageClassName="responsive2" /> */}
                                <p className="card-school">GHPS - Gudibande</p>
                            </div>
                        </div>


                        <div className="img-card">
                            <div className="new-card">
                                <img className="responsive2" src={D7} />
                                {/* <CompressImageFromURL imageUrl={D7} imageClassName="responsive2" /> */}
                                <p className="card-school">GLPS Chakenahalli - Yediyur</p>
                            </div>
                        </div>


                        <div className="img-card">
                            <div className="new-card">
                                <img className="responsive2" src={D8} />
                                {/* <CompressImageFromURL imageUrl={D8} imageClassName="responsive2" /> */}
                                <p className="card-school">Chintamani - Muddalahalli</p>
                            </div>
                        </div>


                        <div className="img-card">
                            <div className="new-card">
                                <img className="responsive2" src={D9} />
                                {/* <CompressImageFromURL imageUrl={D9} imageClassName="responsive2" /> */}
                                <p className="card-school">GHS - Maruthipura</p>
                            </div>
                        </div>

                        <div className="img-card">
                            <div className="new-card">
                                <img className="responsive2" src={D10} />
                                {/* <CompressImageFromURL imageUrl={D10} imageClassName="responsive2" /> */}
                                <p className="card-school">GLPS - Davanagere</p>
                            </div>
                        </div>
                        </Slider>
                        </div>
                        </div>

            {/* Where we have been starting */}
            <div className="countpart">
                <h3 className="futuretext">Where we have been</h3>
                <div className="countpart-layer">
                    <div className="countpart-container">
                        <Count endnumber={5} durationnumber={3} names="States" imaage={state} />
                        <Count endnumber={332} durationnumber={3} names="Events" imaage={school} plus="+" />
                        <Count endnumber={52000} durationnumber={5} names="Beneficiaries" imaage={peo} plus="+"/>
                        <Count endnumber={10000} durationnumber={5} names="Volunteer Hours" imaage={volunteer} plus="+"/>
                    </div>
                </div>
            </div>

            {/* Be a part future starting */}
            <div className="part-box">
                <h3 className="futuretext">Be a part of future</h3>
                <Swiper spaceBetween={30} centeredSlides={true} autoplay={{ delay: 2500, disableOnInteraction: false, }} pagination={{ clickable: true, }} navigation={true} modules={[Autoplay, Pagination, Navigation]} className="mySwiper">
                    <SwiperSlide>
                        <div className="part-container">
                            <div className="part-column">
                                <img src={part1} className="part-responsive" alt="" />
                                {/* <CompressImageFromURL imageUrl={part1} imageClassName="part-responsive" /> */}
                                <div className="part-text-container">
                                    <h4 className="eventname">Sarkari hiriya prathamika shale</h4>
                                    <h5 className="eventloc">
                                        <img className="eventimg" src={location} />
                                        {/* <CompressImageFromURL imageUrl={location} imageClassName="eventimg" />*/}
                                         Kolar</h5> 
                                    <div className="part-btn">
                                        <Link style={{ textDecoration: 'none' }} to="/Donate"><Button style="button-y" title="Lend a hand" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="part-container">
                            <div className="part-column">
                                <img src={part2} className="part-responsive" alt="" />
                                {/* <CompressImageFromURL imageUrl={part2} imageClassName="part-responsive" /> */}
                                <div className="part-text-container">
                                    <h4 className="eventname">Sarkari hiriya prathamika shale</h4>
                                    <h5 className="eventloc">
                                        <img className="eventimg" src={location} />
                                        {/* <CompressImageFromURL imageUrl={location} imageClassName="eventimg" /> */}
                                          Baniga</h5>
                                    <div className="part-btn">
                                        <Link style={{ textDecoration: 'none' }} to="/Donate"><Button style="button-y" title="Lend a hand" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>


                    <SwiperSlide>
                        <div className="part-container">
                            <div className="part-column">
                                <img src={part3} className="part-responsive" alt="" />
                                <div className="part-text-container">
                                    <h4 className="eventname">Sarkari hiriya prathamika shale</h4>
                                    <h5 className="eventloc">
                                        <img className="eventimg" src={location} /> Chendur</h5>
                                        {/* <CompressImageFromURL imageUrl={location} imageClassName="eventimg" />  */}
                                    <div className="part-btn">
                                        <Link style={{ textDecoration: 'none' }} to="/Donate"><Button style="button-y" title="Lend a hand" /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>

            {/* Contributors starting */}
            <div className="bg-img"></div>
            <div className="Contributors-container" id="contribute">
                <span>
                <h3 className="contribute-text">Contributors</h3>
                <Swiper effect={"coverflow"} grabCursor={true} centeredSlides={true} slidesPerView={"auto"} coverflowEffect={{ rotate: 0, stretch: 0, depth: 150, modifier: 10, slideShadows: false, }} autoplay={{ delay: 3000, disableOnInteraction: false }} modules={[EffectCoverflow, Pagination, Autoplay]} className="Contributors">
                    {/* {data.map((item, i) => {
                        return <SwiperSlide className="Contributors-slide" key={i}>
                            <div className="swipe-card">
                            
                                <img src={`https://ouradmins.coolboiler.com//Images/${item.file_name}`} />
                                <h3 className="Contributors-name">{item.Title}</h3>
                                <h3 className="Contributors-desc">{item.Description}</h3>
                            </div>
                        </SwiperSlide>
                    })} */}
                    <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">
                                <img src={AD} />
                                {/* <CompressImageFromURL imageUrl={AD} /> */}
                                <h3 className="Contributors-name">Anand</h3>
                                <h3 className="Contributors-desc">Great opportunity to serve others</h3>
                            </div>
                        </SwiperSlide>
                            <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">
                                <img src={BG} />
                                {/* <CompressImageFromURL imageUrl={BG} /> */}
                                <h3 className="Contributors-name">BetteGowda</h3>
                                <h3 className="Contributors-desc">No gift is small to make a difference</h3>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">
                                <img src={MNB} />
                                {/* <CompressImageFromURL imageUrl={MNB} /> */}
                                <h3 className="Contributors-name">Mahesh</h3>
                                <h3 className="Contributors-desc">Giving is not just about making a donation, it’s about making a difference</h3>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">                            
                                <img src={MS} />
                                {/* <CompressImageFromURL imageUrl={MS} /> */}
                                <h3 className="Contributors-name">Mallikarjuna</h3>
                                <h3 className="Contributors-desc">Smiles of others inspire me</h3>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">
                                <img src={MP} />
                                {/* <CompressImageFromURL imageUrl={MP} /> */}
                                <h3 className="Contributors-name">Murugan</h3>
                                <h3 className="Contributors-desc">Want to take society to the next level</h3>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">
                            
                                <img src={TS} />
                                {/* <CompressImageFromURL imageUrl={TS} /> */}
                                <h3 className="Contributors-name">Thippesh</h3>
                                <h3 className="Contributors-desc">I am discovering success at rural eyes</h3>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">
                            
                                <img src={VB} />
                                {/* <CompressImageFromURL imageUrl={VB} /> */}
                                <h3 className="Contributors-name">Vishwanatha</h3>
                                <h3 className="Contributors-desc">I am here to change your pessimistic thoughts in to optimistic ones</h3>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">                            
                                <img src={JK} />
                                {/* <CompressImageFromURL imageUrl={JK} /> */}
                                <h3 className="Contributors-name">Kishore</h3>
                                <h3 className="Contributors-desc">Don’t worry about returns</h3>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">                            
                                <img src={VC} />
                                {/* <CompressImageFromURL imageUrl={VC} /> */}
                                <h3 className="Contributors-name">Venkatesh</h3>
                                <h3 className="Contributors-desc">It always feels good to help to people</h3>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">                            
                                <img src={SM} />
                                {/* <CompressImageFromURL imageUrl={SM} /> */}
                                <h3 className="Contributors-name">Sushma</h3>
                                <h3 className="Contributors-desc">The time is always right to do what is right</h3>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">                            
                                <img src={SH} />
                                {/* <CompressImageFromURL imageUrl={SH} /> */}
                                <h3 className="Contributors-name">Sudha</h3>
                                <h3 className="Contributors-desc">We make a living by what we get, but we make a life by what we give</h3>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">                            
                                <img src={VK} />
                                {/* <CompressImageFromURL imageUrl={VK} /> */}
                                <h3 className="Contributors-name">Vasantha Kumar</h3>
                                <h3 className="Contributors-desc">I believe in spreading happiness and positivity in my surroundings</h3>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">                            
                                <img src={AM} />
                                {/* <CompressImageFromURL imageUrl={AM} /> */}
                                <h3 className="Contributors-name">Ananta Murali</h3>
                                <h3 className="Contributors-desc">Want to see smile in children's life</h3>
                            </div>
                        </SwiperSlide>
{/*  */}
                        <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">                            
                                <img src={Bb} />
                                {/* <CompressImageFromURL imageUrl={Bb} /> */}
                                <h3 className="Contributors-name">Babanna</h3>
                                <h3 className="Contributors-desc">Always feel good to help others</h3>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">                            
                                <img src={LK} />
                                {/* <CompressImageFromURL imageUrl={LK} /> */}
                                <h3 className="Contributors-name">Lokesh</h3>
                                <h3 className="Contributors-desc">Want to see smile</h3>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">                            
                                <img src={SK} />
                                {/* <CompressImageFromURL imageUrl={SK} /> */}
                                <h3 className="Contributors-name">Shivakumar</h3>
                                <h3 className="Contributors-desc">Spread happiness</h3>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">                            
                                <img src={ST} />
                                {/* <CompressImageFromURL imageUrl={ST} /> */}
                                <h3 className="Contributors-name">Santhosh</h3>
                                <h3 className="Contributors-desc">Happy to serve others</h3>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">                            
                                <img src={Th} />
                                {/* <CompressImageFromURL imageUrl={Th} /> */}
                                <h3 className="Contributors-name">Thara</h3>
                                <h3 className="Contributors-desc">Happiness doesn’t result from what we get, but from what we give</h3>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">                            
                                <img src={Ma} />
                                {/* <CompressImageFromURL imageUrl={Ma} /> */}
                                <h3 className="Contributors-name">Manu</h3>
                                <h3 className="Contributors-desc">Those who are happiest are those who do the most for others</h3>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">                            
                                <img src={MR} />
                                {/* <CompressImageFromURL imageUrl={MR} /> */}
                                <h3 className="Contributors-name">Maruthi</h3>
                                <h3 className="Contributors-desc">Remember that the happiest people are not those getting more, but those giving more</h3>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">                            
                                <img src={Ra} />
                                {/* <CompressImageFromURL imageUrl={Ra} /> */}
                                <h3 className="Contributors-name">Raganna</h3>
                                <h3 className="Contributors-desc">Service to others is the rent you pay for your room here on earth</h3>
                            </div>
                        </SwiperSlide>


                        <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">                            
                                <img src={Sak} />
                                {/* <CompressImageFromURL imageUrl={Sak} /> */}
                                <h3 className="Contributors-name">Sashikumar</h3>
                                <h3 className="Contributors-desc">Everyone can be great because everyone can serve</h3>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">                            
                                <img src={Thi} />
                                {/* <CompressImageFromURL imageUrl={Thi} /> */}
                                <h3 className="Contributors-name">Thimmesh</h3>
                                <h3 className="Contributors-desc">Think of giving not as a duty, but as a privilege</h3>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">                            
                                <img src={SP} />
                                {/* <CompressImageFromURL imageUrl={SP} /> */}
                                <h3 className="Contributors-name">Shilpa</h3>
                                <h3 className="Contributors-desc">No one has ever become poor from giving.</h3>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">                            
                                <img src={PR} />
                                {/* <CompressImageFromURL imageUrl={PR} /> */}
                                <h3 className="Contributors-name">Prasanna</h3>
                                <h3 className="Contributors-desc">Always give without remembering and always receive without forgetting.</h3>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">                            
                                <img src={PD} />
                                {/* <CompressImageFromURL imageUrl={PD} /> */}
                                <h3 className="Contributors-name">Pradeep</h3>
                                <h3 className="Contributors-desc">As we work to create light for others, we naturally light our own way.</h3>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">                            
                                <img src={MG} />
                                {/* <CompressImageFromURL imageUrl={MG} /> */}
                                <h3 className="Contributors-name">Manjunath</h3>
                                <h3 className="Contributors-desc">We make a living by what we get. We make a life by what we give.</h3>
                            </div>
                        </SwiperSlide>
                                           
                        <SwiperSlide className="Contributors-slide">
                            <div className="swipe-card">                            
                                <img src={MH} />
                                {/* <CompressImageFromURL imageUrl={MH} /> */}
                                <h3 className="Contributors-name">Manohar</h3>
                                <h3 className="Contributors-desc">Remember that the happiest people are not those getting more, but those giving more.</h3>
                            </div>
                        </SwiperSlide>
                </Swiper>
                </span>
            </div>
            <Footer />
        </div>

    );
}

export default Home;