import React, { useState } from 'react';
import CountUp from "react-countup";
import VisibilitySensor from 'react-visibility-sensor';

function Count({endnumber, durationnumber, names, plus, imaage, font}) {
    const[focus, setFocus] = useState(false)
    return (
      <CountUp start={focus ? 0 : null} end={endnumber} duration={durationnumber} >{({ countUpRef }) => (
      <>
        <VisibilitySensor onChange={isVisible => { if (isVisible) { setFocus(true);}}}>
        <div className="countpart-card">
        <img src={imaage} className="count-image"/>
        <span className="countpart-text" ref={countUpRef}/><a className="countpart-text">{plus}</a>&nbsp;
        <span className="countpart-names">{names}</span>
        </div>

        </VisibilitySensor>
      </>
    )}
  </CountUp>

    );
}

export default Count;