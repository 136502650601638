import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import '../Assets/Additionalcss/Whoweare.css';
import Arrow from '../Components/Arrow';
import kiran from '../Assets/Images/Founders/KK.jpg'
import anitha from '../Assets/Images/Founders/Anitha.jpg'
import aman from '../Assets/Images/Founders/aman.jpg.jfif'
// import ladder from '../Assets/Images/ladder.png'

function Whoweare() {
    return (
        <div>
            <Header />
            <Arrow />
            <div className="col-lg-12 col-sm-12 col-md-12">
                <div className="who-container">
                    <h3 className="whowehead">Our mission is to empower humanity to be self reliant and live with dignity</h3>
                    <p className="contends">KDP Foundation was founded on 10/10/2013. We are registered 12AA and 80G of income tax act 1961 and have been approved by the commissioner of income tax, Bengaluru No#CIT(E)BLR/80G/2017-18/10109 dated July 24th, 2017. Donations made to the trust are eligible for deduction under section 80G as specified under the income tax act 1961 and rules applicable from time to time.</p>
                    <h5 className="side-title"> Vision</h5>
                    <p className="contends">Uplift the current less fortunate so they can be a part of our mission. <br />Inspire others to join our journey. <br />Use technology to create a platform that enhances the ability to reach the needy directly.</p>

                    <h5 className="side-title">We Believe In</h5>

                    <p className="contends">"Making a difference". That's the mantra by which we operate. We believe that through our actions if we enhance even one person's life then our goal has been achieved. 'We are the most fortunate' and with that understanding we serve the less fortunate by providing support in any form or shape possible.</p>

                    <div className="founder">Our Founders</div>
                    <div className="photocontainer">
                        <div className="founder-contain">
                            <img src={kiran} alt="" className="photo" />
                            <div className="descs">Kiran Kemmannu</div>
                        </div>
                        <div className="founder-contain">
                            <img src={anitha} alt="" className="photo" />
                            <div className="descs">Anita Rao</div>
                        </div>
                        <div className="founder-contain">
                            <img src={aman} alt="" className="photo" />
                            <div className="descs">Aman Rao</div>
                        </div>

                    </div>
                    {/* <h3 className="title">Our Achievements</h3> */}
                    {/* <table className="tab-center">
                        <tbody>
                            <tr>
                                <td>2020-2021</td>
                                <td rowSpan="6"><img className="tab-img" src={ladder} /></td>
                                <td>2019-2020</td>
                            </tr>
                            <tr>
                                <td>2017-2018</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>2016-2017</td>
                            </tr>
                            <tr>
                                <td>2015-2016</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>2014-2015</td>
                            </tr>
                            <tr>
                                <td>2014-2015</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table> */}
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Whoweare;