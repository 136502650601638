import React, { useCallback, useEffect, useRef, useState } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Button from "../Components/Button";
import "../Assets/Additionalcss/Donate.css";
import Arrow from "../Components/Arrow";
import { Image_URL } from "../Config/Base";

function Donate() {
  const [isModalOpen, setModalIsOpen] = useState(false);
  function toggleModal() {
    setModalIsOpen(!isModalOpen);
  }
  return (
    <div>
      <Header />
      <Arrow />
      <div className="donate-part">
        <h4 className="text-center">MAKE A DIFFERENCE</h4>
        <div className="text-center dtn-btn">
          <input
            type="button"
            className="button-y"
            value="Donate Now"
            onClick={(e) => {
              toggleModal(e);
            }}
          />
        </div>
      </div>
      <Footer />
      {isModalOpen ? <Modal /> : null}
    </div>
  );
}
export default Donate;

// Modal popup

function Modal() {
  const [eighteeg, setEighteeg] = useState(false);
  const [anom, setAnom] = useState(false);
  function Close(e) {
    e.preventDefault();
    window.location.reload();
  }

  useEffect(() => {
    const Script = document.createElement("script");
    const Form = document.getElementById("donateForm");
    Script.setAttribute(
      "src",
      "https://checkout.razorpay.com/v1/payment-button.js"
    );
    Script.setAttribute("data-payment_button_id", "pl_J8TS3c26WogegT");
    if (Form) {
      Form.appendChild(Script);
    }

    const Script2 = document.createElement("script");
    const Form2 = document.getElementById("donateForm2");
    Script2.setAttribute(
      "src",
      "https://checkout.razorpay.com/v1/payment-button.js"
    );
    Script2.setAttribute("data-payment_button_id", "pl_JA2U1sVzF2Mfgm");
    if (Form2) {
      Form2.appendChild(Script2);
    }
  }, [eighteeg, anom]);

  function disp80() {
    setEighteeg(true);
    setAnom(false);
  }
  function dispanom() {
    setAnom(true);
    setEighteeg(false);
  }

  return (
    <div className="modal__backdrop">
      <div className="modal__container">
        <div className="modal__head">
          <p className="modal__close" onClick={(e) => Close(e)}>
            <i className="far fa-times-circle"></i>
          </p>
        </div>
        <p className="options">Require 80G</p>
        <div className="modaloption">
          <div className="custom-control custom-radio">
            <input
              type="radio"
              className="custom-control-input"
              name="Donate"
              value="80g"
              id="80g"
              onClick={() => disp80()}
            />
            <label className="custom-control-label" for="80g">
              Yes
            </label>
          </div>
          <div className="custom-control custom-radio">
            <input
              type="radio"
              className="custom-control-input"
              name="Donate"
              value="anonymous"
              id="anonymous"
              onClick={() => dispanom()}
            />
            <label className="custom-control-label" for="anonymous">
              No
            </label>
          </div>
        </div>
        {eighteeg ? (
          <form className="eighteeg-btn" id="donateForm"></form>
        ) : null}
        {/* { anom ? <form className="eighteeg-btn" id="donateForm2"></form> : null} */}
        {anom ? <form className="eighteeg-btn" id="donateForm2"></form> : null}
      </div>
    </div>
  );
}

// [11:39 am] Sudha Harish
// <form><script src="https://checkout.razorpay.com/v1/payment-button.js" data-payment_button_id="pl_J8TS3c26WogegT" async> </script> </form>

// [11:39 am] Sudha Harish
// use this button for 80G Yes

// [11:40 am] Sudha Harish
// <form><script src="
// https://checkout.razorpay.com/v1/payment-button.js"
//  data-payment_button_id="pl_JA2U1sVzF2Mfgm" async> </script> </form>
