// Live
export const BASE_URL = "https://testingapi.kdpfoundation.org/api";
export const Image_URL = "https://testingapi.kdpfoundation.org";
export const Live_URL = "http://localhost:3001/"

// Local
// export const BASE_URL = "https://localhost:44393/api";
// export const Image_URL = "https://localhost:44393";

// LOCAL
// export const BASE_URL = "https://localhost:44317/api";
// export const Image_URL = "https://localhost:44317";