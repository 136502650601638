import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BASE_URL } from "../../Config/Base";

export const fetchTodos = createAsyncThunk('fetchTodos', async () => {
    const response = await fetch(`${BASE_URL}/Foundation/getJourney`);
    const data = await response.json();
    sessionStorage.setItem('todos', JSON.stringify(data));
    return data;
});

const todoSlice = createSlice({
    name: "todo",
    initialState: {
        isLoading: false,
        data: JSON.parse(sessionStorage.getItem('todos')) || null,
        isError: false
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTodos.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchTodos.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchTodos.rejected, (state) => {
            state.isError = true;
        });
    }
});

export default todoSlice.reducer;
