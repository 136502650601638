import React from 'react';
import '../Assets/Additionalcss/Footer.css'
import { Link } from "react-router-dom";

function Footer() {
    const date = new Date().getFullYear()
    return (
        <div className="text-center footer fwidth">
        <h6 className="footer-contact">CONTACT US</h6>
        <p className="footer-contact-text">80, First Floor, 6th Main, <br/>
        2nd Cross, JP Nagar 3rd Phase, Bengaluru,<br/>
        Karnataka-560078<br/>
        {/* <strong>Phone :</strong> +91 80 26581990<br/> */}
        <strong>Email :</strong> <a className="email-yellow" href="mailto:contact@kdpfoundation.org">contact@kdpfoundation.org</a>
        </p>
        <div className="social-links">
        <a href="https://www.youtube.com/channel/UC_HZqdc_sZKVIG3ifJ-WIkQ?app=desktop" target="_blank" rel="noreferrer"><i className="fab fa-youtube"></i></a> <a href="https://m.facebook.com/Kdpfoundation/" target="_blank" rel="noreferrer"><i className="fab fa-facebook"></i></a> <a href="https://www.instagram.com/1kdpfoundation/" target="_blank" rel="noreferrer"><i className="fab fa-instagram"></i></a> <a href="https://www.linkedin.com/uas/login?session_redirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2F65605636%2Fadmin%2F" target="_blank" rel="noreferrer"><i className="fab fa-linkedin"></i></a>
        </div>
        <div className="Footer-body">
        <p className="footer-text">© Copyright {date} All Rights Reserved | <Link to="/Terms" className="foot">Terms & Conditions</Link> | <Link to="/Privacy" className="foot">Privacy Policy</Link></p>
        </div>
        </div>
    );
}

export default Footer;