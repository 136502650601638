import React from "react";
import { Carousel } from "react-bootstrap";
import kdp1 from "../Assets/Images/kdpslide/kdp1.jpg";
import kdp2 from "../Assets/Images/kdpslide/kdp2.jpg";
import kdp3 from "../Assets/Images/kdpslide/kdp3.jpg";
import kdp4 from "../Assets/Images/kdpslide/kdp4.jpg";
import kdp5 from "../Assets/Images/kdpslide/kdp5.jpg";
import kdp6 from "../Assets/Images/kdpslide/kdp6.jpg";
import kdp7 from "../Assets/Images/kdpslide/kdp7.jpg";
import CompressImageFromURL from './CompressImageFromURL';

function ImgCarousel() {
  return (
    <Carousel
      fade={true}
      pause={false}
      controls={false}
      prevLabel={false}
      nextLabel={false}
      indicators={false}
    >
      <Carousel.Item interval={3000}>
        <div className="Starting-swiper-slide">
          <div className="Starting-container">
            <div className="Starting-text-container">
            <p className="Starting-text">Making a difference through education one child at a time</p>
            </div>
            <img className="Starting-img" src={kdp6} alt="" />
            {/* <CompressImageFromURL imageUrl={kdp6}  imageClassName="Starting-img"/> */}
          </div>
        </div>
      </Carousel.Item>

      <Carousel.Item interval={2000}>
        <div className="Starting-swiper-slide">
          <div className="Starting-container">
            <div className="Starting-text-container">
            <p className="Starting-text">Find peace for your mind by helping others</p>
            </div>
            <img className="Starting-img" src={kdp2} alt="" />
            {/* <CompressImageFromURL imageUrl={kdp2}  imageClassName="Starting-img"/> */}
          </div>
        </div>
      </Carousel.Item>

      <Carousel.Item interval={2000}>
        <div className="Starting-swiper-slide">
          <div className="Starting-container">
            <div className="Starting-text-container">
            <p className="Starting-text">Any donation can a make a big difference!</p>
            </div>
            <img className="Starting-img" src={kdp3} alt="" />
            {/* <CompressImageFromURL imageUrl={kdp3}  imageClassName="Starting-img"/> */}
          </div>
        </div>
      </Carousel.Item>

      <Carousel.Item interval={2000}>
        <div className="Starting-swiper-slide">
          <div className="Starting-container">
            <div className="Starting-text-container">
            <p className="Starting-text">Wonder happens when your smile covers someone's life</p>
            </div>
            <img className="Starting-img" src={kdp4} alt="" />
            {/* <CompressImageFromURL imageUrl={kdp4}  imageClassName="Starting-img"/> */}
          </div>
        </div>
      </Carousel.Item>

      <Carousel.Item interval={2000}>
        <div className="Starting-swiper-slide">
          <div className="Starting-container">
            <div className="Starting-text-container">
            <p className="Starting-text">We are here for charity not business</p>
            </div>
            <img className="Starting-img" src={kdp5} alt="" />
            {/* <CompressImageFromURL imageUrl={kdp5}  imageClassName="Starting-img"/> */}
          </div>
        </div>
      </Carousel.Item>

      <Carousel.Item interval={2000}>
        <div className="Starting-swiper-slide">
          <div className="Starting-container">
            <div className="Starting-text-container">
            <p className="Starting-text">Your extra, someone's need</p>
            </div>
            <img className="Starting-img" src={kdp1} alt="" />
            {/* <CompressImageFromURL imageUrl={kdp1}  imageClassName="Starting-img"/> */}
          </div>
        </div>
      </Carousel.Item>

      <Carousel.Item interval={2000}>
        <div className="Starting-swiper-slide">
          <div className="Starting-container">
            <div className="Starting-text-container">
            <p className="Starting-text">Selfish people! No one is. There is good in everyone!</p>
            </div>
            <img className="Starting-img" src={kdp7} alt="" />
            {/* <CompressImageFromURL imageUrl={kdp7}  imageClassName="Starting-img"/> */}
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
  );
}
export default ImgCarousel;
