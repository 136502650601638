import React, { useEffect, useState } from "react";
import Footer from "../Components/Footer";
import { BASE_URL } from "../Config/Base";
import { Image_URL } from "../Config/Base";
import Header from "../Components/Header";
import {
  useLocation,
  useHistory,
  useNavigate,
  generatePath,
} from "react-router-dom";
import Axios from "axios";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import location2 from "../Assets/Images/location.png";

function DistrictEvent() {
  // const history = useHistory();
  const navigate = useNavigate();
  const [searchyear, setSearchyear] = useState("");
  const [dispyear, setdispyear] = useState(true);
  const year = ["2023", "2022", "2021", "2020", "2019", "2018", "2017", "All"];
  const InitialYear = ["2023", "2022", "2021", "2020", "2019", "2018", "2017"];
  const filtertext = useLocation();
  const [data, setData] = useState([]);
  const [griddata, setGriddata] = useState([]);
  const location = useLocation();
  const main_loc = location.state.main_location;

  function funcyear(item) {
    setSearchyear(item);
    setdispyear(false);
  }

  function initialfuncyear(e, item) {
    setSearchyear("All");
    setdispyear(false);
  }

  function back() {
    if (filtertext.length == 0) {
      navigate("/Journey", { state: { eletter: filtertext.state.eletter } });
    } else {
      navigate("/Journey");
    }
  }
  const handleProceed = (id, loc) => {
    navigate(generatePath("/Newseemore/:id", { id }), {
      state: { eMlocation: loc },
    });
  };

  const seemorehandle = (id, loc) => {
    navigate(generatePath("/Seemoredata/:id", { id }), {
      state: { eMlocation: loc, eyear: searchyear },
    });
  };

  useEffect(() => {
    Axios({
      method: "GET",
      url: `${BASE_URL}/Foundation/getDistrictSort?event_main_location=${main_loc}`,
    }).then((resp) => {
      jj(resp.data);
      setGriddata(
        resp.data.sort(function (a, b) {
          return new Date(b.event_date) - new Date(a.event_date);
        })
      );
    });

    const datas2 =
      filtertext.state.eyear === undefined
        ? funcyear("All")
        : funcyear(filtertext.state.eyear);
  }, []);
  const jj = (ss) => {
    const newArray = [];
    const uniqueObject = {};
    for (const i in ss) {
      const objTitle = ss[i]["event_location"];
      uniqueObject[objTitle] = ss[i];
    }
    for (const i in uniqueObject) {
      newArray.push(uniqueObject[i]);
    }
    setData(newArray);
  };

  return (
    <div>
      <Header />
      <div className="Journey-container">
        <div className="Journey-box-district">
          <div className="districtevents-back">
            <p className="backoption" onClick={back}>
              <i className="fas fa-caret-left"></i> Back
            </p>
            <p>
              <img
                className="eventimg"
                src={location2}
                style={{ width: "20px" }}
              />{" "}
              {main_loc} {griddata.length <= 2 ? "Event" : "Events"}
            </p>
          </div>

          <div className="dis-top">
            <div className="container">
              <div className="row">
                <div className="grid-contain">
                  {searchyear == "" ? (
                    <div className="grid-contain">
                      {InitialYear.map((item, j) => {
                        return (
                          <>
                            <div
                              key={j}
                              className="col disgrid"
                              onClick={(e) => funcyear(item)}
                            >
                              {item}
                            </div>{" "}
                          </>
                        );
                      })}
                      <div
                        className="col disgrid  active-grid"
                        onClick={(e) => initialfuncyear(e)}
                      >
                        All
                      </div>
                    </div>
                  ) : (
                    <div className="grid-contain">
                      {year.map((item, j) => {
                        return (
                          <>
                            <div
                              key={j}
                              className={
                                searchyear == item
                                  ? "col disgrid  active-grid"
                                  : "col disgrid"
                              }
                              onClick={(e) => funcyear(item)}
                            >
                              {item}
                            </div>{" "}
                          </>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            {searchyear == "All"
              ? griddata.map((item) => {
                  return (
                    <div
                      className="journeycard"
                      onClick={(e) => {
                        seemorehandle(item.id, item.event_name);
                      }}
                    >
                      <div className="journey-date">
                        <p className="journey-dat">
                          <span className="journey-da">
                            {new Date(item.event_date).getDate()}
                          </span>
                          <br />
                          {new Date(item.event_date).toLocaleString("default", {
                            month: "short",
                          })}
                        </p>
                      </div>
                      <LazyLoadImage
                        className="card-img"
                        effect="blur"
                        src={`${Image_URL}${item.image_path}`}
                      />
                      <div className="card-body">
                        <h6 className="card-title">
                          <b>{item.event_name}</b> at {item.event_location}
                        </h6>
                        <div
                          onClick={(e) => {
                            seemorehandle(item.id, item.event_name);
                          }}
                        >
                          See More
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}

            {searchyear != "All" &&
            griddata.filter((item) => {
              if (searchyear == 0) {
                return item;
              } else if (
                searchyear == new Date(item.event_date).getFullYear()
              ) {
                return item;
              }
            }).length == 0 ? (
              <p className="textCenter">No Event Found</p>
            ) : (
              griddata
                .filter((item) => {
                  if (searchyear == 0) {
                    return item;
                  } else if (
                    searchyear == new Date(item.event_date).getFullYear()
                  ) {
                    return item;
                  }
                })
                .map((item, a) => {
                  return (
                    <div
                      className="journeycard"
                      key={a}
                      onClick={(e) => {
                        seemorehandle(item.id, item.event_name);
                      }}
                    >
                      <div className="journey-date">
                        <p className="journey-dat">
                          <span className="journey-da">
                            {new Date(item.event_date).getDate()}
                          </span>
                          <br />
                          {new Date(item.event_date).toLocaleString("default", {
                            month: "short",
                          })}
                        </p>
                      </div>
                      <LazyLoadImage
                        className="card-img"
                        effect="blur"
                        src={`${Image_URL}${item.image_path}`}
                      />
                      <div className="card-body">
                        <h6 className="card-title">
                          <b>{item.event_name}</b> at {item.event_location}
                        </h6>
                        <div
                          onClick={(e) => {
                            seemorehandle(item.id, item.event_name);
                          }}
                        >
                          See More
                        </div>
                      </div>
                    </div>
                  );
                })
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default DistrictEvent;
