import React, { useState, useEffect } from 'react';
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import Moment from 'react-moment';
import '../Assets/Additionalcss/Seemore.css'
import vidone from '../Assets/Images/vid1.mp4'
import vidtwo from '../Assets/Images/vid2.mp4'
import vidthree from '../Assets/Images/vid3.mp4'
import copyurl from '../Assets/Images/copy.png'
import { useLocation, useParams, useNavigate, generatePath,  useHistory } from "react-router-dom";
import Axios from 'axios';
import Avatar from '@mui/material/Avatar';
import Slide from 'react-reveal/Slide';
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import ReactPlayer from 'react-player'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from '../Config/Base'
import { Image_URL } from '../Config/Base'
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {FacebookShareButton, TwitterShareButton, WhatsappShareButton,} from "react-share";
import {FacebookIcon,  TwitterIcon,  WhatsappIcon,} from "react-share";
import location2 from '../Assets/Images/location.png'

function Seemoredata() {
  const { id } = useParams();
  const filtertext = useLocation();
  const navigate = useNavigate();
  let formData = new FormData();
  const [Gallery, setGallery] = useState([])
  const [evmain, setEvmain] = useState('')
  const [eventloc, setEventloc] = useState([])
  const [filtdata, setfiltdata] = useState([])
  const [imavid, setImavid] = useState(false)
  const [Load, setLoad] = useState(true)
  const [imgdisp, setImgdisp] = useState()
  const [viddisp, setviddisp] = useState(vidone)
  const [data, setData] = useState([])
  const [com, setCom] = useState([])
  const [comment, setcomment] = useState(true)
  const [inputname, setInputName] = useState(true)
  const [inputcomment, setInputComment] = useState(true)
  const [backoption, setbackoption] = useState(true)
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const location = useLocation();
  const state = location.state;
   const main_loc = evmain;
   const [postcom, setPostcom] = useState({
    event_id: id,
    user_name: "",
    place:"",
    title:"Foundation_Comments",
    comment_desc: "",
  })

  const url = window.location.href;
  const message = 'Hey there I am share event to you'
  const facebookapi = `https://www.facebook.com/sharer.php?u=${url}`
  const whatsappapi = `https://wa.me/?text=${url}&text=${message}`
  const twitterapi = `https://twitter.com/intent/tweet?text=${url}.${message}`

  const handleProceed = (id, event_location) => {
    setbackoption(false)
    navigate(generatePath("/Districtseemore/:id", { id }));
    window.location.reload()
  };
  function back() {
    navigate(generatePath("/DistrictEvent"), { state: { main_location: main_loc, eyear:filtertext.state.eyear } });
  }
  useEffect(() => {
    Axios({
      method: "GET",
      url: `${BASE_URL}/Foundation/getParticular?Id=${id}`
    }).then((resp) => {
      setData(resp.data)
      dd(resp.data)
      setLoad(false)
      setEvmain(resp.data.map((e) => e.event_main_location))
    })
    Axios({
      method: "GET",
      url: `${BASE_URL}/Foundation/getComment?Id=${id}`
    }).then((resp) => {
      setCom(resp.data)
    })
    Axios({
      method: "GET",
      url: `${BASE_URL}/Foundation/getEventsGalleries?id=${id}`
    }).then((resp) => {
      setGallery(resp.data)
      Galleryimage(resp.data)
    })



  }, [])

  const Galleryimage = (ss) => {
    const gi = ss.map((e) => e.image_path)
    setImgdisp(gi[0])
  }


  const dd = (ss) => {
    const elocation = ss.map((e) => e.event_location)
    setEventloc(elocation)
    const emlocation = ss.map((e) => e.event_main_location)
    const evname = ss.map((e) => e.event_name)
    Axios({
      method: "GET",
      url: `${BASE_URL}/Foundation/getSeemoreList?Id=${id}&event_location=${elocation}&event_main_location=${emlocation}&event_name=${evname}`
    }).then((resp) => {
      // if(evname == )
      setfiltdata(resp.data.sort(function (a, b) {
        return new Date(a.event_date) - new Date(b.event_date);
      }));
    })

  }

  function validation() {
    
    formData.append('event_id', postcom.event_id)
    formData.append('user_name', postcom.user_name)
    formData.append('comment_desc', postcom.comment_desc)
    formData.append('place', postcom.place)
    formData.append('title', postcom.title)
    let un = document.getElementById('user_name').value
    let uc = document.getElementById('comment_desc').value
    let up = document.getElementById('place').value
    if (un.length == "" || un == null) {
      alert('Enter Your Name')
    }
    else if (up.length == "" || up == null) {
      alert('Give Your Place')
    }
    else if (uc.length == "" || uc == null) {
      alert('Give Your Comment')
    }
    else {
      Axios.post(`${BASE_URL}/Foundation/AddComment`, formData)
        .then(res => {
          if (res.data == "Success") {
            alert("Your Comments Added!")
            window.location.reload(false);
          }
        })
    }
  }

  const togglePreview = () => {
    setIsPreviewOpen(!isPreviewOpen);
  };

  function store(e) {
    const newdata = { ...postcom }
    newdata[e.target.id] = e.target.value
    setPostcom(newdata)
    e.preventDefault()
    let un = document.getElementById('user_name').value
    let uc = document.getElementById('comment_desc').value
    if (un.length == "" || un == null) {
      setInputName(false)
    }
    else if (uc.length == "" || uc == null) {
      setInputComment(!inputcomment)
    }
  }

  return (
    <div>
      <Header />
      <div className="seemore-container">
        <div className="social-share-container">
          {/* <div className="facebook" onClick={() => window.open(facebookapi)}>
            <i className="fab fa-facebook" title="Facebook"></i>
          </div> */}
          {/* <div className="whatsapp" onClick={() => window.open(whatsappapi)}>
            <i className="fab fa-whatsapp" title="Whatsapp"></i>
          </div>
          <div className="twitter" onClick={() => window.open(twitterapi)}>
            <i className="fab fa-twitter" title="Twitter"></i>
          </div> */}

<FacebookShareButton
        url={url}>
        <FacebookIcon size={32} round bgStyle={{fill:'#ffc107'}}/>
      </FacebookShareButton>
      < TwitterShareButton
        url={url}>
        <TwitterIcon size={32} round bgStyle={{fill:'#ffc107'}}/>
      </ TwitterShareButton>
      <WhatsappShareButton
        url={url}>
        <WhatsappIcon size={32} round bgStyle={{fill:'#ffc107'}}/>
      </WhatsappShareButton>


          <div className="urlcopy" onClick={() => toast.info("Url has been copied")}>
            <CopyToClipboard text={url}>
              {/* <i className="fas fa-copy" title="Copy Url" style={{fill:'#ffc107'}}></i> */}
              <img src={copyurl} width="36px"/>
            </CopyToClipboard>
          </div>
        </div>
        <div className="seemore-card">
          <div className="events-back">
          <p className="backoption" onClick={back}><i className="fas fa-caret-left"></i> Back</p>
          {data.map((item) => {
          return <p><img className="eventimg" src={location2} style={{width:'20px'}}/> {item.event_main_location} {filtdata.length <= 1  ? "Event" : "Events"}</p>
          })}
          </div>
          {data.map((item) => {
            return <div className="story">
              <h5 className="seemore-event">{item.event_name} at {item.event_location} on {new Date(item.event_date).getDate()}-{new Date(item.event_date).toLocaleString('default', { month: 'short' })}-{new Date(item.event_date).getFullYear()}</h5>
            </div>
          })}
          {/* <div className="seemore-option-IV">
            <div className="option-IV" onClick={() => setImavid(false)}>Images</div>
            <div className="option-IV" onClick={() => setImavid(true)}>Videos</div>
          </div> */}
          {Load == true ? <div className="seemore-skeleton" style={{marginTop:'10%'}}>
            <Stack spacing={15}>
              <div className="skeleton">
                <div className="skeleton-div">
                  <div className="skeleton-contain">
                    <div className="skeleton-row">
                      <Skeleton className="width-height" variant="rectangular" height={350} />
                    </div>
                    <div className="skeleton-col">
                      <Skeleton className="skelet" variant="rectangular" height={150} width={200} />
                      <Skeleton className="skelet" variant="rectangular" height={150} width={200} />
                      <Skeleton className="skelet" variant="rectangular" height={40} width={200} />
                    </div>
                  </div>
                </div>
              </div>
            </Stack>
          </div>
            :

            imavid == false ?

              <div className="seemare-img-section">
                <div className="contain-images">
                  <div className="main-picture">
                    <div className="picture">
                    <img className="pic-disp" src={`${Image_URL}${imgdisp}`}  onClick={togglePreview} />
                    </div>
                  </div>

                  <div className="picture-list">
                    {Gallery.map((item) => {
                      return <div onClick={() => setImgdisp(item.image_path)} className={imgdisp == item.image_path ? 'pic active' : 'pic'} >
                        <LazyLoadImage
                className="pic-disp"
    effect="blur"
    src={`${Image_URL}${item.image_path}`} />
                      </div>
                      
                    })}
                  </div>
                </div>
              </div>

              :
              <div className="seemare-img-section">
                <div className="contain-images">
                  <div className="main-picture">
                    <div className="picture">
                      <ReactPlayer className="pic-disp" url={viddisp} controls={true} width="450px" height="300px" />
                    </div>
                  </div>
                  <div className="picture-list">
                    <div onClick={() => setviddisp(vidone)} className={viddisp == vidone ? 'pic active' : 'pic'} >
                      <video src={vidone} className="pic-disp" />
                    </div>
                    <div onClick={() => setviddisp(vidtwo)} className={viddisp == vidtwo ? 'pic active' : 'pic'}>
                      <video src={vidtwo} className="pic-disp" />
                    </div>
                    <div onClick={() => setviddisp(vidthree)} className={viddisp == vidthree ? 'pic active' : 'pic'}>
                      <video src={vidthree} className="pic-disp" />
                    </div>
                  </div>
                </div>
              </div>
          }
          {isPreviewOpen && (
        <div className="overlay" onClick={togglePreview}>
          <img className="full-image"  src={`${Image_URL}${imgdisp}`} alt="Full Preview" />
        </div>
      )}
          {data.map((item) => {
            return <div className="story">
              <p className="seemore-story">{item.story}</p>
            </div>
          })}

          <div className="comment-section-container">
            <input type="button" className={comment == false ? "seemore-comment-btn-clicked" : "seemore-comment-btn"} value={comment == false ? "Back" : "Leave a comment"} onClick={comment == false ? () => setcomment(true) : () => setcomment(false)} />
            {comment == true ?
              <>
                {com.length == 0 ? <p>No Comments</p> : com
                  .map((item, r) => {
                    return <div className="comment" key={r}>
                      <div className="comment-flex">
                        <Avatar sx={{ bgcolor: 'green' }}>{item.user_name.split(' ')[0][0]}</Avatar>
                        <p className="comment-name">{item.user_name}</p>
                      </div>
                      <p className="comment-date"><Moment fromNow>{item.created_DateTime}</Moment></p>
                      <p className="comment-desc">{item.comment_desc}</p>
                    </div>
                  })}
              </>
              :
              <>
                <Slide bottom >
                  <form>
                    <input name="user_name" id="user_name" autoComplete="off" className="comment-input" type="text" placeholder="Enter Your Name" onChange={(e) => store(e)} /> <br />
                    <input name="place" id="place" autoComplete="off" className="comment-input" type="text" placeholder="Enter Your Place" onChange={(e) => store(e)} />
                    <textarea name="comment_desc" id="comment_desc" autoComplete="off" className="comment-input" type="text" placeholder="Comment Here" onChange={(e) => store(e)} /> <br />
                    <div className="">
                      <input type="button" className="button-37" value="Submit" onClick={(e) => validation(e)} />
                    </div>
                  </form>
                </Slide>
              </>
            }
          </div>
        </div>

        {/* {listdisp == 0 ? null : */}
        <div className="optiongrid">
          {filtdata.length <= 1 ? null : filtdata.map((item, e) => {
            return <>
<div className={new Date(item.event_date).getFullYear() == data.map((e) => new Date(e.event_date).getFullYear()) ? "box-three-active" : "box-three"} onClick={(e) => {
                handleProceed(item.id, item.event_location)
              }}><p>{item.event_name} - {new Date(item.event_date).getFullYear()}</p></div>
            </>
          })}
        </div>
      </div>

      <Footer />
      <ToastContainer position="top-left" autoClose={100} hideProgressBar newestOnTop={false} closeOnClick={false} rtl={false} pauseOnFocusLoss={false} draggable={false} pauseOnHover={false} />
    </div>
  );
}
export default Seemoredata;